import {DialogMsgSupplier} from "../core/suppliers/dialog-msg-supplier";
import {ObjectDTO} from "../core/dtos/object-dto";

/**
 * Interface pour la création des datagrids
 */
export abstract class  IYoDatagrid{



  /**
   * Aide contextuelle
   * @returns {DialogMsgSupplier}
   */
  abstract help() : DialogMsgSupplier;

  /**
   * Modification d'un objet
   * @param {ObjectDTO} dto
   */
  abstract openEditObject(dto : ObjectDTO);

  /**
   * Supprimer un objet
   * @param {ObjectDTO} dto
   */
  abstract openDeleteObject(dto : ObjectDTO);

  /**
   * Initialiser le Reactive Form
   * @param {ObjectDTO} dto
   */
  abstract initForm(dto:ObjectDTO);

}
